import React, { useState, useEffect } from "react";
import Layout from "../layout/Layout";
import TextField from "../components/form/TextField";
import { useFormik } from "formik";
import {
  new_password_schema,
  new_password_initial_values,
} from "../utils/user";
import { getFormError } from "../utils/form";
import useGetResetPasswordOperation from "../hooks/resetpassword/useGetResetPassword";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import Loader from "../components/form/Loader";
import { staticPage } from "../../config/staticPage";

const ResetPasswordPage = () => {
  const { state, validateToken, changePassword } =
    useGetResetPasswordOperation();
  const [isValid, setIsValid] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const urlToken = searchParams.get("token");

  // const token = "";

  useEffect(() => {
    if (!state.isChecked) {
      const checkValidity = async () => {
        const res = await validateToken(urlToken);
        // setIsChecked(true);
        if (res?.status === 200) {
          setIsValid(true);
        } else {
          setIsValid(false);
        }
      };
      checkValidity();
    }
  }, [state.isChecked]);

  const formik = useFormik({
    initialValues: new_password_initial_values,
    validationSchema: new_password_schema,
    onSubmit: async (values) => {
      const res = await changePassword(values?.confirm_password, urlToken);
      if (res) {
        navigate("/login");
      }
    },
  });

  return (
    <>
      <div className="page-wrapper">
        <div className="page-heading-section">
          <div className="container">
            <h1 className="page-title">Change Password</h1>
          </div>
        </div>
      </div>

      <div className="container">
        {state?.isLoading === true ? (
          <Loader />
        ) : isValid === false ? (
          <p className="text-center">
            Your token is expired or invalid. Please regenerate it.
          </p>
        ) : (
          <form
            className="form create-account-form form-mid"
            onSubmit={formik.handleSubmit}
          >
            <div className="form-row">
              <div className="form-field">
                <TextField
                  id={"new-password"}
                  name={"new_password"}
                  label={"New Password"}
                  labelFor={"new-password"}
                  type={"password"}
                  onChange={formik.handleChange}
                  touched={formik.touched["new_password"]}
                  error={getFormError(formik.errors, "new_password")}
                  required
                />
              </div>

              <div className="form-field">
                <TextField
                  id={"confirm-password"}
                  name={"confirm_password"}
                  label={"Confirm Password"}
                  labelFor={"confirm-password"}
                  type={"password"}
                  onChange={formik.handleChange}
                  error={getFormError(formik.errors, "confirm_password")}
                  touched={formik.touched["confirm_password"]}
                  required
                />
              </div>
            </div>

            <div className="form-oneline">
              <button type="submit" class="button" disabled={!isValid}>
                Save Password
              </button>
            </div>
          </form>
        )}
      </div>
    </>
  );
};
export const Head = () => {
  let login_nav = staticPage;
  let loginDetails = null;
  for (const obj of login_nav) {
    if (obj.name === "reset-password") {
      loginDetails = obj;
      break; // Exit the loop once the desired object is found.
    }
  }
  return (
    <>
      <title>{loginDetails.title}</title>
      <meta name="description" content={loginDetails.description} />
    </>
  );
};

ResetPasswordPage.Layout = Layout
export default ResetPasswordPage;
